import ModalForm from "@/components/templateux/modal/modal-form";
import PopupMenuOption from "@/components/templateux/popup-menu-option";
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import { config, lookupPost } from "@/lib/config";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { openWindow } from "@/lib/utils/openWindow";
import { slugify } from "@/lib/utils/slugify";
import router from "next/router";
import { Fragment, useEffect, useRef, useState } from "react";
import toast, { toastConfig } from "react-simple-toasts";
import Popup from "reactjs-popup";
import PostForm from "../../profile/post/post-form";
import RotaryLogo from "../rotary-logo";

export default function MenuCreateDropdown({
     classColor
    ,session
    ,userAuthData
    ,open
    ,set_open
}) {

  const { isConfirmed } = useConfirm();

  const [isMounted,set_isMounted] = useState(false)

  useEffect(() => {
    set_isMounted(true)
  }, [])


    //HOOKS ABOVE SECURITY CALLS
    const cache_date = +new Date
    const [cache, set_cache] = useState(encodeURIComponent(cache_date?.toString()))
    const [isOpen, set_isOpen] = useState(null); //modal

  useEffect(()=>{
      set_isOpen(null)
  },[])
  
  //SUBMISSION STATES
  const [submitting,set_submitting] = useState(false);
  const [processing,set_processing] = useState(-1);
  const [changes,set_changes] = useState(false);
  const [form_reset,set_form_reset] = useState(1);
  const [typevalue,set_typevalue] = useState(0)

  
//MODAL FUNCTIONS

async function closeModal() {

  if (changes) {
    
    const confirmed = await isConfirmed('You have unsaved changes. Are you sure you want to close?');
    // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
   if (confirmed) {
      set_isOpen(null)
      set_form_reset(1)
      set_changes(false)
    }
  } else {
    set_isOpen(null)
    set_form_reset(1)
  }
}

function openModal(n,value) {
  if (n==0) set_typevalue(value);
  set_isOpen(n);
}



async function submitHandler(
  submitbody
 ,api_destination
 ,api_method='POST'
 ,api_headers= {
   'Content-Type': 'application/json',
 }
 ,toast_message='Your changes were saved.'
) {
set_submitting(true)

 //console.log(JSON.stringify(submitbody))

try {
  
  //INFO SUBMIT

    set_submitting(true)
    const res = await fetch(api_destination, {
      method: api_method,
      headers: api_headers,
      body: JSON.stringify(submitbody),
    })

    //console.log("res",res)

    set_changes(false);
    set_submitting(false)
    const json = await res.json()
    if (json) {

      
      const { 
          outcome
          , post_id
          , post_title
          , slug
          , user_name
        } = json[0][0]
      // console.log(json,submitbody);
      if (outcome?.toString()=="1") {
        toastConfig({
          time: 5000,
          className: '',
          position: 'right'
        });
        toast(toast_message, { 
          time: 3000
          , className: ''
          , clickable: true
          , clickClosable: false 
        });

      } else {
        alert(outcome)
      }
      let submit_cache = new Date;
      set_cache(encodeURIComponent(submit_cache?.toString()));
      set_isOpen(null);
      router.push(`/${user_name}/posts/${slugify(post_id,slug)}`)
     
    }

    

  
} catch (e) {
 throw Error(e.message)
}
}


// const ref = useRef(null);
// const closeMenu = () => {if (ref.current) ref.current.close()};

const PopupTrigger = (
                      <div 
                          onClick={()=>{ open !== 'create' ? set_open('create') : set_open('')}}
                          className={`${classColor} flex-0 flex items-center content-center rounded-md shadow-md hover:animate-pulse mr-2`}>
                        <div className="flex-1 rounded-l-md">
                          <div className={` 
                          
                            cursor-pointer text-center 
                            pl-2 pr-2 xs:pr-1 py-1 flex font-bold text-md
                            
                            
                            `}
                          >
                            <SvgJsx 
                              type={"fill"}
                              icon={"plus-sm"}
                              className={`h-6  w-6 flex `}
                              title={"Create or Go Live"}
                            />
                          </div>
                        </div>
                        <div 
                        className="flex-0 cursor-pointer hidden xs:block
                           pr-1 py-1 xs:pr-3 rounded-r-md
                          "
                        >
                          Add a listing
                        </div>
                        
                      </div>
)




    return (<>
            <div className="menu flex items-center content-center  flex-0 z-50">
              {isMounted && session &&
              <>
                  <div className='group'>
                            <Popup
                              position="bottom left"
                              open={open}
                              onOpen={()=>set_open('create')}
                              //onClose={()=>set_open('')}
                              trigger={PopupTrigger}
                              //on="click"
                              closeOnDocumentClick
                              closeOnEscape
                              mouseLeaveDelay={300}
                              mouseEnterDelay={0}
                              contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent', zIndex:1999 }}
                              arrow={false}
                              nested
                              //ref={isMounted ? ref : null}
                            >
                              <div className="menu ring-0 fixed top-[47px] right-[0px] w-full sm:w-64 ">
                                <div className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2 
                                              bg-red-600 "
                                >
                                 


                                {[
                                  lookupPost("image",-1),
                                  lookupPost("text",-1)
                                ]?.map((e,index) => 
                                  <Fragment key={index}>
                                    <PopupMenuOption
                                      isDisplay={true}
                                      onClick={()=>{
                                        openModal(0,e?.value);
                                        set_open('');
                                      }}
                                      icon={e?.icon}
                                      title={e?.action}
                                      colorClass={`
                                      bg-red-900 hover:bg-red-700 hover:text-white text-red-100 `}
                                    />
                                  </Fragment>
                                  )}


                            
                                  
                                </div>
                              </div>
                            </Popup>
                          </div>
                  
                          <ModalForm
                            title={`New ${lookupPost("",typevalue)?.text}`}
                            modal_content={(<>
                                        <PostForm
                                          submitHandler={submitHandler}
                                          submitting={submitting}
                                          set_submitting={set_submitting}
                                          processing={processing}
                                          set_processing={set_processing}
                                          set_changes={set_changes}
                                          changes={changes}
                                          max_files={config.post.max_files}

                                          //post info
                                          post_id={isOpen}
                                          itemdata={{}}
                                          itemdataisLoading={false}
                                          itemdataisError={false}

                                          ownerdata={userAuthData}
                                          userdata={userAuthData}

                                          show_graphic={false}
                                          form_reset={form_reset}
                                          set_form_reset={set_form_reset}
                                          actionText={'Save changes'}
                                          postcache={cache}
                                          set_postcache={set_cache}
                                          navtype={'post'}
                                          helptype={'content'}

                                          typevalue={typevalue}
                                          set_typevalue={set_typevalue}
                                        />
                                      </>)}
                            isOpen={isOpen}
                            closeModal={closeModal}
                          />
                </>
              }
              </div>
            </>)
}