
import { signIn } from "next-auth/client";
import Button from "../button/button";

function MenuButton({
     open
    ,avatar
    ,session
    ,loading
}) { 

    if (!session ) {
      return (<>
        <MenuButtonWrapper>
            <Button 
              className='
                        
                        
                        '
              onClick={signIn}
            >
            <div className="text-white hover:opacity-80 flex items-center content-center px-2 py-0.5 flex-0 shrink-0 rounded-md ">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5 hidden sm:block">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="sm:ml-2 ">
              Join Us
            </span>
            </div>
          </Button>
        </MenuButtonWrapper> 
      </>)
    }


    return (<>
                <MenuButtonWrapper>
                            
                            <div className='
                            '>

                              {avatar}
                             
                            </div>
                            
                            
                          
                          
            </MenuButtonWrapper> 

    </>)
}

function MenuButtonWrapper({children}) {
  return (<>
      <div>
        <div 
          className={`flex flex-none items-center content-center cursor-pointer whitespace-nowrap border group  p-0.5 rounded-md text-sm font-medium 
          
          text-white   hover:text-white
        border-transparent
          `}
            
          id="main-menu" 
          aria-haspopup="true"
        >
         {children}                 
        </div>
      </div>
  
  </>)
}

export default MenuButton