import { footerlist, footerlist_sub, profile } from "@/lib/config"
import { hasData } from "@/lib/utils/hasData"
import Link from "next/link"
import { Fragment, useEffect, useRef, useState } from "react"
import Popup from "reactjs-popup"
import RotaryLogo from "../rotary-logo"
import PopupMenuOptionLink from "../popup-menu-option-link"
import SvgJsx from "../svg/svg-jsx"
import UsernameSearchModal from "@/components/settings/user/user-name/username-search-modal"
import getMessage from "@/lib/utils/getMessage"

function MenuLogoDropdown({
  navtype
  ,open
  ,set_open
}) {

 //search
 let [isOpen, set_isOpen] = useState(false)
 const [results,set_results] = useState(null)
 const [search,set_search] = useState('')

  function closeModal() {
    set_isOpen(false)
  }
  function openModal() {
      set_isOpen(true);
  }

  //regular stuff
  const [isMounted,set_isMounted] = useState(false)
  useEffect(() => {
    set_isMounted(true)
  }, [])




   const ref = useRef(null);
   const closeMenu = () => {if (ref.current) ref.current.close()};

    const PopupTrigger = <div 
                                  onClick={()=>set_open('logo')}
                                  className={`
                                    group cursor-pointer font-bold text-2xl flex items-center content-center border border-transparent px-1 py-0.5 rounded-md
                                  
                                  
                                  hover:bg-gray-700 text-white hover:text-gray-100 
                                  `}>   
                                    {/* <RotaryLogo 
                                        classSize='w-7 h-7 mx-auto '
                                        classAnimate=' animate-spin-slow'
                                        bgColor='transparent'
                                        centerColor='#111827'
                                        pointerColor='#ff0000'
                                        holeColor='#111827'
                                        dialerColor='#ffffff'
                                        size='64'
                                    /> */}
                            
                                   
                                    <>
                                      {/* <div className="flex-0 inline-block ml-2   ">
                                        {process.env.NEXT_PUBLIC_BRAND_NAME}
                                      </div> */}
                                      <div className="flex-0 inline-block ml-2   ">
                                       Sce<span className="text-blue-200">near</span>y
                                      </div>
                                      <div 
                                        className={`  font-bold cursor-pointer ml-2 mt-1 
                                        ` }          
                                      >
                                        <div className=" 
                                                    text-gray-700 group-hover:text-gray-400
                                                     "
                                        >
                                          <SvgJsx 
                                            type='fill'
                                            icon={'chevron-down-sm'}
                                            className={`  h-6 w-6 `}
                                            title={'open'}
                                          />
                                        </div>
                                      </div>
                                    </>
                                   
                                    
                                </div>

return (
    <div className="menu flex items-center content-center  flex-0 z-50">
    {!isMounted &&
    PopupTrigger
    }
    {isMounted &&
    <>
    <Popup
     
      position="bottom left"

      open={open}
      onOpen={()=>set_open('logo')}
      //onClose={()=>set_open('')}
      trigger={PopupTrigger}
      //on="click"
      closeOnDocumentClick
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent', zIndex:1999 }}
      arrow={false}
      nested
      ref={isMounted ? ref : null}
    >
      <div className="menu ring-0 fixed top-[44px] left-[0px] w-full xs:w-64">
        <div className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2
                      bg-gray-800 
                      border border-gray-700"
        >
          



          {footerlist.filter((e,index) => index < 4 ).map((e,index) => 
          <Fragment key={index}>
          
            <PopupMenuOptionLink
              icon={e.icon}
              iconclass={e.iconclass}
              name={e.name}
              type={e.type}
              url={e.url}
              navtype={navtype}
              behavior={e.behavior}
            />
            
            
          </Fragment>
          )}

          <PopupMenuOptionLink
              icon={'search-sm'}
              iconclass={''}
              name={'User Search'}
              type={'search'}
              url={'/search'}
              navtype={navtype}
              behavior={'button'}
              onClick={()=> {
                closeMenu();
                set_isOpen(true)
              }}
            />


            
          <div className="mt-4 flex-1 inline-flex divide-gray-700 justify-center">
            {footerlist_sub.map((e)=> 
              <div 
                key={e.name}
                className="my-auto px-1 mx-1 flex-0 flex flex-row items-center content-center text-xs"
              >

              <Link href={`${e.url}`}>
                <a 
                  target={`${e.internal == '1' ? '' : '_blank'}`}
                  rel={`${e.internal == '1' ? '' : 'noreferrer'}`}
                  className={
                  `
                   flex-0
                             text-gray-400 hover:text-white
                              cursor-pointer group
                              flex flex-row items-center content-center flex-1
                              
                `}>
                  <div className="flex-1">
                  
                      {e.name} 

                  </div>
                </a>
              </Link>
            </div>
            )}

         </div>
         
        {/* COPYRIGHT */}
        <div className="flex items-center content-center xs:mx-3 mt-4 mx-auto w-full xs:w-auto text-center">
          <div className=" flex-1 flex-col justify-center text-2xs text-center w-full ">
            <Link href={`${process.env.NEXT_PUBLIC_SERVER_WEBSITE}`}>
              <a 
                target={``}
                rel={``}
                className={
                `
                  flex-1
                                text-gray-400
                            cursor-pointer group
                            flex items-center content-center
              `}>
                <div className="my-2 w-full">
                  <div>
                    © {new Date().getFullYear()}, 
                      <span className="ml-1  text-gray-400 group-hover:text-white">
                        {process.env.NEXT_PUBLIC_PARENT_BRAND}
                      </span>.
                  </div>
                  <div className="block ml-1">
                    All rights reserved.
                  </div>
                </div>
              </a>
            </Link>
          </div>
          
        </div>



        

        </div>
      </div>
    </Popup>
    </>
      }


    <UsernameSearchModal
      results={results}
      set_results={set_results}
      search={search}
      set_search={set_search}
      isOpen={isOpen}
      set_isOpen={set_isOpen}
      closeModal={closeModal}
      noresults={getMessage("noresults")}
      profiledata={null}
    />

    
    

  </div>
  );

 
}

export default MenuLogoDropdown