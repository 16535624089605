

import AvatarFlair from "./avatar-flair"
import { config } from "@/lib/config"
import { truncate } from "@/lib/utils/truncate"

function AuthorCredit({
     display_data
    ,isPlaying=false
    ,profiledata
    ,show_avatar=true
    ,show_user_name=true
    ,show_flair=true
    ,custom_flair=''
    ,bol_mod=0
    ,containerClassBig='w-8 h-7 '
    ,containerClassSmall='w-5 h-7 '
    ,bgClass='w-6 h-6 '
    ,avatarClass='w-6 h-6 mr-2 border-2 border-transparent '
    ,rotaryContainerClass='w-[28px] h-6 mr-3'
    ,rotaryInternalClass='w-8 h-8'
    ,sizes={
         width:25
        ,height:25
        ,quality:50
    }
    ,chatWidth=config.chat.windowoffset.minmessagewidth
}) {



    return (<>
                    <div className="flex-0 flex items-center content-center   h-8">
                      
                          {/* AVATAR + NAME CONTAINER */}
                          <div className={`flex-0 flex flex-none items-center content-center group`}>

                            {/* AVATAR CONTAINER */}
                            {show_avatar &&
                            <div className="mr-2">
                            <AvatarFlair   
                              isPlaying={false}
                              isContributor={profiledata?.contributor_level > 0}
                              avatar_url={profiledata?.avatar_url}
                              avatar_url_sfw={profiledata?.avatar_url_sfw}
                              avatar_crdate={profiledata?.avatar_crdate}
                              id={profiledata?.id}
                              user_name={profiledata?.user_name}
                              bgClass='w-8 h-8'
                              containerClass='w-8 h-7 -mt-[2px]'
                              avatarClass='w-8 h-8 border-2 border-transparent '
                              rotaryContainerClass='w-8 h-8 mr-4'
                              rotaryInternalClass='w-8 h-9'
                              sizes={{
                                width:36
                              ,height:36
                              ,quality:50
                              }}
                            /></div>
                            }
                            
                            {/* USERNAME - ALL CONTAINER */}
                            {show_user_name &&
                            <div className="flex items-center content-center">

                              {/* USERNAME - TOP */}
                              <div className="flex items-center content-center">

                                 {/* USERNAME */}
                                <div className={`flex-0 font-bold text-gray-200 mr-1 text-sm  ${chatWidth < config.chat.windowoffset.minmessagewidth ? "" : "xs:text-base"}`}>
                                  {profiledata?.user_name}
                                </div>
                              
                               

                              </div>


                            </div>
                            }

                          </div>

                          
                    </div>
                    
    </>)
}


export default AuthorCredit