import { hasData } from "@/lib/utils/hasData";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import SvgJsx from "../svg/svg-jsx";

export default function ModalForm({
    title
    ,modal_content
    ,isOpen
    ,closeModal
}) {

    return (<>
                <Transition appear show={hasData(isOpen)} as={Fragment}>
                    <Dialog
                    as="div"
                    open={hasData(isOpen)}
                    className="fixed inset-0 z-100 overflow-y-auto bg-[rgb(0,0,0,0.85)]"
                    onClose={()=>{}}
                    >
                    
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Dialog.Overlay className="fixed inset-0 " />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        >
                        <div className="max-w-3xl
                                        inline-block w-full border transform p-6 my-8 overflow-hidden text-left align-middle transition-all shadow-xl rounded-2xl
                                        bg-gray-900 border-gray-700">
                          
                            <div className="sm:flex items-center content-center">
                                <div className="flex-1">
                               
                                   

                                    <div className="flex items-center content-center border-b pb-4
                                                    border-gray-700
                                    ">
                                        <div className="flex-1">
                                             <Dialog.Title
                                                as="h3"
                                                className="text-xl font-bold leading-6 
                                                text-white"
                                            >
                                                {title}
                                            </Dialog.Title>
                                        </div>
                                        <div className="flex-0">
                                            <div
                                                className="inline-flex justify-center cursor-pointer p-2 "
                                                onClick={closeModal}
                                            >
                                                <SvgJsx 
                                                                type={'fill'}
                                                                    
                                                                icon={'x-circle-sm'}
                                                                className={`group-hover:animate-ping h-5 w-5 
                                                                                                            text-white hover:text-gray-200`}
                                                                title={'Become a Supporter'}
                                                            />
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                
                            </div>


                           
                            
                            
                            <div className="mt-4 w-full content-center item-center flex">

                                {modal_content}

                            </div>
                            

                            
                            
                        </div>
                        </Transition.Child>
                    </div>
                    </Dialog>
                </Transition>
    </>)
}