import getMessage from "@/lib/utils/getMessage";
import { hasData } from "@/lib/utils/hasData";
import { useEffect, useState } from "react";
import LoadingRotary from "./loading-rotary";

export default function LoadingInner({
    showRandom=true
    ,message=null
    ,sizeClass='w-32 h-32'
}) {


  const [msg,set_msg] = useState(showRandom ? getMessage("loading") : message)

  const reloadMessage = (showRandom) => {
    //if (!hasData(message)) {
        let thismsg = showRandom ? getMessage("loading") : message
        set_msg(thismsg)
    //}
}

  

//   useEffect(() => {
//     //if (typeof window !== "undefined") reloadMessage();
//   }, []);


    
  




    return (<>
        <div className="cursor-pointer group px-10 my-10 m-auto align-center text-center text-gray-400   "
        onClick={()=> reloadMessage(showRandom)}
        >
     
            <LoadingRotary 
                classSize={`${sizeClass} mx-auto  block`}
                classAnimate='animate-spin-slow'
                bgColor='#000000aa'
                centerColor='#000000'
                pointerColor='#c0c0c0'
                holeColor='#000000aa'
                dialerColor='#c0c0c0aa'
            />
            <div 
                className="block mt-10  group-hover:text-white"
                
            > 
            {showRandom ? `${msg}...` : message}

            </div>
        </div>
    </>)
}