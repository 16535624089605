import {useState} from 'react'
import axios from 'axios'
import { config } from '@/lib/config'
import Image from 'next/image'
import { getAvatarUrl } from '@/lib/utils/getAvatarUrl';
import Link from 'next/link';
import SvgJsx from '@/components/templateux/svg/svg-jsx';

export default function UsernameSearchInput({
  results
  ,set_results
  ,search
  ,set_search
}) {

 
  async function getUsernameLookup(search) {

    return new Promise(resolve => (
      axios
        .get(`/api/public/v0/user/search-name?name=${search}`)
        .then(function (response) { 
          //set_userlookup(response.data.user_id)
          //console.log(response?.data)
          set_results(response?.data)
        })
        .catch((e) => console.log('error',e))
        .finally(() => {
            resolve
        })
    ))

  }


    return (<>
        {/* USER_NAME */}
          <div className='flex items-center content-center w-full border rounded-l-md
          
          bg-gray-800
          '>
            <div className="flex-0 px-2
            ">
              <>
              <SvgJsx
                    type="outline"
                    icon={"search"}
                    className={`h-8 w-8 text-gray-500`}
                    title={'Search'}
                  />
              </>
            </div>
            <div className='flex-1 w-full relative'>
              
                        
              
              <input 
                id="search"
                name="search"
                type="text"
                placeholder={"Search for User"}
                onChange={(e) => {
                  getUsernameLookup(e.target.value);
                  set_search(e.target.value);
                }}
                value={search}
                //autoComplete="current-user_name" 
                required 
                className={` appearance-none block w-full px-3 py-2 border-none rounded-md shadow-sm focus:outline-none text-2xl
                relative
                text-white placeholder-gray-500 bg-gray-800
                `} 
                maxLength={config.max_username_length}
                minLength={config.min_username_length}
                autoComplete={"off"}
              />
              
                
            </div>
          </div>
    </>)
}

