import { hasData } from "./hasData";
import { padZeroVariable } from "./padZero";
export function extractTimeObject(timestr) {

  if (!hasData(timestr)) return null
  
  const arr_timestr = timestr?.toString()?.split(':')

  return {
    hours:arr_timestr[0],
    minutes:arr_timestr[1],
    seconds:arr_timestr[2],
  };
}

export function extractDateObject(datestr) {

  //console.log("new Date()?.toISOString()",new Date()?.toISOString())

  if (!hasData(datestr)) return null


  let vdatestr = new Date(datestr)?.toISOString()
  
  
  const arr_datestr = vdatestr?.slice(0,10).toString()?.split('-')
  const arr_timestr = vdatestr?.slice(11,vdatestr.length).toString()?.split(':')

  return {
    year:arr_datestr[0],
    month:arr_datestr[1],
    date:arr_datestr[2],
    hour:arr_timestr[0],
    minute:arr_timestr[1],
    second: Math.round(parseInt(arr_timestr[2]?.split('.')[0])),
    millisecond: Math.round(parseInt(arr_timestr[2]?.split('.')[1]?.split('Z')[0])),
    meridian: parseInt(arr_timestr[0]) < 12 ? 'AM' : 'PM'
  };
}

export function domInputStringifyDateObject(dateobj) {
  return `${padZeroVariable(dateobj?.year, 4,'0')}-${padZeroVariable(dateobj?.month, 2,'0')}-${padZeroVariable(dateobj?.date, 2,'0')}`
}

export function isostringifyDateObject(dateobj) {


  //console.log("exceptTwelve(dateobj?.hour)",exceptTwelve(dateobj?.hour),dateobj)

  return `${padZeroVariable(dateobj?.year, 4,'0')}-${padZeroVariable(dateobj?.month, 2,'0')}-${padZeroVariable(dateobj?.date, 2,'0')}T${padZeroVariable((dateobj?.meridian == "AM" || dateobj?.hour >= 11) ? exceptTwelve(dateobj?.hour) : exceptTwelve(parseInt(dateobj.hour) + 12), 2,'0')}:${padZeroVariable(dateobj?.minute, 2,'0')}:${padZeroVariable(dateobj?.second, 2,'0')}.${padZeroVariable(dateobj?.millisecond, 3,'0')}Z`
}

export function exceptTwelve(n) {
  if (n==12 || n==24) {
    return n-12
  }
  return n
}