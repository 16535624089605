import Link from "next/link"
import RotaryLogo from "../rotary-logo"
import GatingButton from "./button"

const GatingMessageInline = ({
    headline=<>Contributor Extras!</>,
    text=<></>
}) => {
    return (<>
                 <Link href='/contribute'>
                  <a target='_blank' rel="noopener noreferrer">
                    <div className=" group lg:flex items-start content-top items cursor-pointer shadow-xl text-center bg-gray-600 border-4 border-gray-700 mt-5 p-5 rounded-xl hover:bg-gray-700 hover:border-white">
                        
                        <div className="flex-1">

                          <div className=" w-full mx-auto">
                            <div className={`flex-0 flex items-center content-center justify-center lg:justify-start text-3xs px-0.5 py-0.5 rounded-full cursor-pointer group`}>
                            
                                <div className={`flex-0 justify-left text-sm  `}>
                                  <div className="flex-0  mr-1 ">
                                   
                                    <RotaryLogo 
                                      classSize={`h-5 w-5 sm:h-10 sm:w-10 mx-auto block`}
                                      classAnimate='animate-spin'
                                      bgColor='transparent'
                                      centerColor='rgba(31,41,55,1)'
                                      pointerColor='#ff0000'
                                      holeColor='rgba(31,41,55,1)'
                                      dialerColor='#ffffff'
                                      size='32'
                                    />
                                  </div>
                                </div>

                                <div className='ml-2 font-bold text-lg sm:text-2xl'>
                                  {headline}
                                </div>

                            </div>
                          </div>

                          <div className="text-center lg:text-left">
                            {text}
                          </div>

                        </div>
                        
                        <div className="mt-2 flex justify-center lg:ml-4">
                          <GatingButton />
                        </div>
                    </div>
                  </a>
                  </Link>
    </>)
}

export default GatingMessageInline