import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import GatingIcon from "./gating/icon";
import GatingMessageInline from "./gating/message-inline";
import SvgJsx from "./svg/svg-jsx";

export default function SelectListItem({
    set_selected_value
   ,selected_value
   ,value
   ,text
   ,premium_level=0 
   ,premium_id=""
   ,premium_tip=`Contributor Only`
   ,userdata={contributor_level:0}
 }) {

  const { isConfirmed } = useConfirm();

   return (<>
   <div
                     onClick={async ()=> {

                      

                      if (premium_level > userdata?.contributor_level) {

                        await isConfirmed({
                          title: `This option is locked`,
                          body: <><GatingMessageInline 
                                  headline={<>Contributor Only Option</>}
                                  text={<>Kindly consider becoming a contributor to unlock it.</>}
                                /></>,
                          confirm:``,
                          cancel:`Close`,
                          show_title: false,
                          show_cancel: true,
                          show_confirm: false
                        });

                      } else {
                        set_selected_value()
                      }
                      
                    }}
                     className={`my-2 rounded-md px-4 py-2 ${premium_level > userdata?.contributor_level ? 'cursor-not-allowed' : 'cursor-pointer'} flex items-top content-top group hover:shadow-md
                               ${selected_value==value
                                 ? `bg-blue-600 hover:bg-blue-500` 
                                 : `bg-gray-700 hover:bg-gray-600`} 
                                 text-gray-200
                               `}
                  >
                     <div className={`flex-0 w-7 
                                     ${selected_value==value 
                                     ? ` opacity-100 ` 
                                     : ` opacity-0 group-hover:opacity-50 `
                                     }`}>
                         <SvgJsx 
                             type={'outline'}
                             icon={'arrow-narrow-right'}
                             className={`h-7 w-7 mr-2 `}
                             title={'selected'}
                         />
                     </div>
                     <div className="ml-2 flex-1">
                       {text}
                     </div>
                     {premium_level > 0 &&
                     <div className="flex-0 ml-2">
                        <GatingIcon
                            id={premium_id}
                            tip={premium_tip}
                            sizeClass={`w-6 h-6`}
                          />
                     </div>
                    }
                   
                  </div>
           </>)
 }