
import ReactTooltip from 'react-tooltip';
import showdown from 'showdown'
import parsehtml from '@/lib/utils/parsehtml'
import { support_messages } from '@/lib/config';
import RotaryLogo from '../rotary-logo';
import { useEffect, useState } from 'react';

const GatingIcon = ({
    id,
    tip=`Contains Contributor-Only Features`,
    sizeClass=`w-6 h-6`,
    placement='right'
}) => {

    var converter = new showdown.Converter()

    const [isMounted,setIsMounted] = useState(false); // Need this for the react-tooltip

    useEffect(() => {
        setIsMounted(true);
    },[]);

    return (<>
            <div className={`relative ${sizeClass}`}
            
            >
                                <div className={`absolute ${sizeClass}`}>
                               
                                  <RotaryLogo
                                      classSize={`${sizeClass} mx-auto  block`}
                                      classAnimate='animate-spin'
                                      bgColor='transparent'
                                      centerColor='rgba(31,41,55,1)'
                                      pointerColor='#ff0000'
                                      holeColor='rgba(31,41,55,1)'
                                      dialerColor='#ffffff'
                                      size='32'
                                  />
                                </div>
                                <div
                                    data-tip
                                    data-for={`helptip-${id}`}
                                  className={`${sizeClass} absolute cursor-pointer`}
                                >
                                </div>
                            </div>
                            {isMounted && <ReactTooltip
                                id={`helptip-${id}`}
                                //@ts-ignore
                                place={`${placement}`} 
                                effect='solid' 
                                clickable={true}
                                delayHide={500}
                                delayUpdate={500}
                                className="reacttooltip rounded-3xl shadow-lg"
                                >
                                    <div className="max-w-[256px] helptips">
                                        {parsehtml(converter.makeHtml(tip || support_messages.missing_info))}
                                    </div>
                                </ReactTooltip>}
    </>)
}

export default GatingIcon